.anthracite {
    --main-color: #121a28;
    --main-color-dark: #0d121b;
    --main-color-light: #595e67;
}

.autumn {
    --main-color: #c3aa00;
    --main-color-dark: #887700;
    --main-color-light: #d5c440;
}

.babyblue {
    --main-color: #2f99cb;
    --main-color-dark: #226a8e;
    --main-color-light: #6fb8db;
}

.beige {
    --main-color: #979266;
    --main-color-dark: #696547;
    --main-color-light: #b6b294;
}

.blue {
    --main-color: #1633c6;
    --main-color-dark: #0f238a;
    --main-color-light: #5d72d8;
}

.bluegrey {
    --main-color: #606c6e;
    --main-color-dark: #434b4d;
    --main-color-light: #8f9899;
}

.brownred {
    --main-color: #3c211b;
    --main-color-dark: #291714;
    --main-color-light: #756360;
}

.chinchilla {
    --main-color: #82a2a3;
    --main-color-dark: #5a7172;
    --main-color-light: #a7bebe;
}

.cyanide {
    --main-color: #00c488;
    --main-color-dark: #00895f;
    --main-color-light: #58d6ab;
}

.ferrari {
    --main-color: #f20000;
    --main-color-dark: #a90304;
    --main-color-light: #f7525a;
}

.florida {
    --main-color: #ed8200;
    --main-color-dark: #a55b00;
    --main-color-light: #f3a858;
}

.fresh {
    --main-color: #91b800;
    --main-color-dark: #658000;
    --main-color-light: #b2cd50;
}

.fuchsia {
    --main-color: #7f1d6f;
    --main-color-dark: #59154e;
    --main-color-light: #a5629a;
}

.greyblue {
    --main-color: #87abc0;
    --main-color-dark: #5e7786;
    --main-color-light: #aac4d3;
}

.hardpink {
    --main-color: #cb326f;
    --main-color-dark: #8e244d;
    --main-color-light: #db709a;
}

.mint {
    --main-color: #00b1c4;
    --main-color-dark: #027c89;
    --main-color-light: #59c8d6;
}

.moss {
    --main-color: #1d3e29;
    --main-color-dark: #142b1d;
    --main-color-light: #607869;
}

.ocre {
    --main-color: #ca892b;
    --main-color-dark: #8d5f1f;
    --main-color-light: #daac6e;
}

.offwhite {
    --main-color: #919191;
    --main-color-dark: #656565;
    --main-color-light: #b2b2b2;
}

.phtalo {
    --main-color: #006996;
    --main-color-dark: #004968;
    --main-color-light: #4896b6;
}

.pink {
    --main-color: #ff3dcb;
    --main-color-dark: #b42a8d;
    --main-color-light: #ff78db;
}

.purple {
    --main-color: #5600c6;
    --main-color-dark: #3c058a;
    --main-color-light: #885ad8;
}

.racing {
    --main-color: #2b6a3a;
    --main-color-dark: #1e4a29;
    --main-color-light: #6b9775;
}

.royal {
    --main-color: #272860;
    --main-color-dark: #1b1c43;
    --main-color-light: #676890;
}

.sky {
    --main-color: #0d7bc6;
    --main-color-dark: #0b568a;
    --main-color-light: #5ba3d7;
}

.spring {
    --main-color: #a2a505;
    --main-color-dark: #717309;
    --main-color-light: #bec05e;
}

.standard {
    --main-color: #00b3c4;
    --main-color-dark: #007d89;
    --main-color-light: #4ccad6;
}

.venice {
    --main-color: #4f6c15;
    --main-color-dark: #384c0f;
    --main-color-light: #84985d;
}

.wine {
    --main-color: #b6262c;
    --main-color-dark: #7f1b1f;
    --main-color-light: #cc676c;
}

.woodburn {
    --main-color: #3b371a;
    --main-color-dark: #292613;
    --main-color-light: #75735f;
}
