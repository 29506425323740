@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --main-color: #00B3C4;
    --main-color-dark: #007D89;
    --main-color-light: #4DCAD6;
    --main-rgba: rgba(0, 125, 137, .9);
    --color1: #ffffff;
    --color2: #000000;
    --color3: #515151;
    --color4: #FF8A00;
    --color5: #9C9C9C;
    --color6: #313131;
    --color7: #F7F7F7;
    --color8: #A4A4A4;
    --color9: #304753;
    --color10: #12B76A;
    --color11: #242424;
    --color12: #666666;
    --color13: #E25046;
    --color14: #E0C28C;
    --color15: #A0AF71;
    --color16: #E0A000;
    --color17: #F08597;
    --color18: #D8864E;
    --color19: #3973B9;
    --color20: #00B3C4;
    --color21: #EF3829;
    --color22: #276FE2;
    --color23: #494B4E;
    --color24: #002427;
}

* {
    -webkit-font-smoothing: antialiased;
}

html {
    scroll-behavior: smooth;
}

::-moz-selection {
    background: var(--main-color);
    color: var(--color1);
}

::selection {
    background: var(--main-color);
    color: var(--color1);
}

body {
    font-family: 'Inter', sans-serif;
}

.cstm-container {
    max-width: 1440px;
}

.top-bar {
    background-color: var(--color2);
    /*   padding: 12.5px 0;*/
    padding: 0;
}

.modal-open header.fixed {
    overflow: hidden;
    width: calc(100% - 17px);
    width: -moz-calc(100% - 16px);
    width: -ms-calc(100% - 16px);
}

.top-contact {
    list-style: none;
    margin: 0;
    padding: 12.5px 0;
    font-size: 0;
}

.top-contact li {
    display: inline-block;
    margin-right: 24px;
}

.top-contact li:first-of-type {
    margin-left: 0;
}
.top-contact li.address:hover {
    margin-left: 0;
}
.top-contact li a,
.top-contact li span {
    color: var(--color1);
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    text-decoration: none;
}

.top-contact .dropdown-menu,
.top-contact .dropdown .btn {
    color: var(--color1);
    font-size: 14px;
    line-height: 21px;
    background-color: var(--color2);
    border: 0;
    border-radius: 0;
    padding: 0 !important;
    vertical-align: unset;
}

.top-contact .dropdown .btn .office-icon {
    font-weight: 900;
    /*font-family: "Font Awesome 5 Free";*/

}

.top-contact .office-filter option {
    background-color: var(--color2);
    color: var(--color1);
    font-size: 14px;
    font-family: 'Inter', 'Font Awesome 5 Free';
    font-weight: 900;
}

.top-contact .btn.dropdown-toggle::after {
    border: 0;
}

.top-contact .dropdown-item:hover,
.top-contact .dropdown-item.active {
    color: var(--color1);
    background-color: var(--main-color);
}

.top-contact .office-filter:focus,
.top-contact .office-filter:active {
    outline: 0;
}

.top-contact .dropdown-menu li {
    margin: 0;
    width: 100%;
}

.top-contact .dropdown-menu {
    font-size: 0;
    margin-left: -13px !important;
    margin-top: 0 !important;
    padding-top: 10px !important;
}

.top-contact li i, .top-contact li svg {
    font-size: 16px;
    color: var(--color1);
    margin-right: 8px;
}

.office-icon {
    font-weight: 900 !important;
    /*font-family: "Font Awesome 5 Free";*/
}

.top-contact li:hover i {
    color: var(--main-color);
}
.top-contact li.address:hover i {
    color: var(--color1);
}
.top-contact .dropdown .btn .office-icon {
    font-weight: 900;
    /*font-family: "Font Awesome 5 Free";*/
    display: inline-block;
}

.top-contact .dropdown-menu li:hover i {
    color: var(--color1);
}

.top-contact li:hover .dropdown-menu li i {
    color: var(--color1);
}

.top-contact .dropdown-menu .dropdown-item {
    padding: 8px 16px;
    display: block;
}

.top-contact .dropdown-menu .office-icon {
    margin-right: 8px;
}

.top-social-media {
    list-style: none;
    padding: 12.5px 0;
    margin: 0;
    font-size: 0;
}

.top-social-media li {
    display: inline-block;
    margin: 0 13px;
}

.top-social-media li a {
    font-size: 20px;
    color: var(--color1);
    line-height: 1;
}

.top-social-media li a:hover {
    text-decoration: none;
    color: var(--main-color);
}


.top-lang {
    list-style: none;
    padding: 12.5px 0;
    margin: 0;
    font-size: 0;
    background-color: var(--main-color);
}

.top-lang li {
    display: inline-block;
    margin: 0 13px;
}

.top-lang li a {
    font-size: 18px;
    color: var(--color1);
    line-height: 1.15;
    text-decoration: none;
}

.top-lang li a:hover {
    text-decoration: none;
    color: var(--color1);
}


.top-lang-mobile {
    list-style: none;
    padding: 12.5px 0;
    margin: 0;
    font-size: 0;
    background-color: var(--main-color);
}

.top-lang-mobile li {
    display: inline-block;
    margin: 0 13px;
}

.top-lang-mobile li a {
    font-size: 18px;
    color: var(--color1);
    line-height: 1.15;
    text-decoration: none;
}

.top-lang-mobile li a:hover {
    text-decoration: none;
    color: var(--color1);
}

.top-contact .dropdown-item .selected,
.top-contact .dropdown-toggle .selected {
    display: none;
}

.locatie-home {
    height: 18.5px;
    width: auto;
    margin-right: 8px;
    vertical-align: sub;
}

.locatie-blank,
.locatie-plus {
    height: 19px;
    width: auto;
    margin-right: 5px;
    vertical-align: sub;
}

/* POSITIONING */

.vcenter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.vcenter-start {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.vcenter-end {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

/* BURGER MENU */
.animated-icon1 {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 5px 0;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

.animated-icon1 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

.animated-icon1 span {
    background: var(--color2);
}

.animated-icon1 span:nth-child(1) {
    top: 0px;
}

.animated-icon1 span:nth-child(2) {
    top: 10px;
}

.animated-icon1 span:nth-child(3) {
    top: 20px;
}

.animated-icon1.open span:nth-child(1) {
    top: 11px;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
}

.animated-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

.animated-icon1.open span:nth-child(3) {
    top: 11px;
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.navbar-toggler {
    border: none;
}

.navbar-toggler:focus {
    box-shadow: none;
    outline: none;
}

.navbar-light .navbar-toggler {
    color: rgba(239, 224, 214, 0.1);
    border-color: rgba(239, 224, 214, 0.1);
}

.first-button {
    outline: 0 !important;
}

header .navbar {
    padding: 0;
}

.main-list .nav-item .nav-link {
    padding: 22px 18px;
    color: var(--color3);
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
}

.main-list .nav-item .nav-link.active {
    color: var(--main-color);
}

.main-list .nav-item .nav-link:hover {
    color: var(--color1);
    background-color: var(--main-color);
    text-decoration: none;
}

.main-menu {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.brand-menu{
    text-decoration: none;
}
.brand-menu:hover,
.brand-menu h2:hover,
.brand-menu h3:hover,
.brand-menu div:hover{
    text-decoration: none;
}
.btn-reserv {
    background-color: var(--color4);
    color: var(--color1) !important;
    padding: 22px 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    text-align: center;
}

.btn-reserv:hover {
    background-color: var(--color4) !important;
    color: var(--color1) !important;
}

.btn-reserv img {
    height: 21px;
    width: auto;
    margin-right: 8px;
}

.logo-icon {
    max-height: 40px;
    object-fit: contain;
    width: auto;
    margin-left: 12px;
    vertical-align: bottom;
}

.logo-title1 {
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    color: var(--color2);
    margin-bottom: 0;
    margin-left: 12px;
}

.logo-title2 {
    color: var(--color5);
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    margin-bottom: 0;
    margin-left: 12px;
}

.main-list li:last-of-type.nav-item {
    padding-right: 0;
}

.fixed {
    position: fixed !important;
    top: 0;
    width: 100%;
    left: 0 !important;
}


.sticky {
    z-index: 999;
    left: 0;
    background-color: #ffffff;
    position: absolute;
    width: 100%;
}



.wrap-hero-img {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.img-hero {
    height: 100vh;
    width: 100%;
    animation: move 42s linear infinite;
    -ms-animation: move 42s linear infinite;
    -webkit-animation: move 42s linear infinite;
    -o-animation: move 42s linear infinite;
    -moz-animation: move 42s linear infinite;
    object-fit: cover;
}


@keyframes move {
    0% {
        -webkit-transform-origin: bottom left;
        -moz-transform-origin: bottom left;
        -ms-transform-origin: bottom left;
        -o-transform-origin: bottom left;
        transform-origin: bottom left;
        transform: scale(1.0);
        -ms-transform: scale(1.0);
        /* IE 9 */

        -webkit-transform: scale(1.0);
        /* Safari and Chrome */

        -o-transform: scale(1.0);
        /* Opera */

        -moz-transform: scale(1.0);
        /* Firefox */
    }

    50% {
        transform: scale(1.2);
        -ms-transform: scale(1.2);
        /* IE 9 */

        -webkit-transform: scale(1.2);
        /* Safari and Chrome */

        -o-transform: scale(1.2);
        /* Opera */

        -moz-transform: scale(1.2);
        /* Firefox */
    }

    100% {
        transform: scale(1.0);
        -ms-transform: scale(1.0);
        /* IE 9 */

        -webkit-transform: scale(1.0);
        /* Safari and Chrome */

        -o-transform: scale(1.0);
        /* Opera */

        -moz-transform: scale(1.0);
        /* Firefox */
    }
}

.wrap-hero-info {
    position: absolute;
    bottom: 100px;
    max-width: 1440px;
}

.hero-title {
    font-weight: 700;
    font-size: 54px;
    line-height: 120%;
    color: var(--color1);
    margin-bottom: 16px;
}

.hero-small-content {
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    color: var(--color1);
    margin-bottom: 26px;
    max-width: 820px;
}

.btn-hero img {
    height: 32px;
    width: auto;
    margin-right: 8px;
}

.btn-hero {
    background-color: #FF8A00;
    color: var(--color1);
    padding: 12px 20px;
    font-weight: 500;
    font-size: 22px;
    line-height: 140%;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
}

.btn-hero:hover {
    color: var(--color1);
    text-decoration: none;
}

.announce-icon {
    height: 28px;
    width: auto;
    vertical-align: sub;
    margin: 0 20px;
}

#outer {
    overflow: hidden;
    background: var(--main-color-dark);
    padding: 14px 0;
    position: absolute;
    bottom: 0;
    max-width: 100%;
    width: 100%;
}

#outer div {
    display: inline-block;
}

#loop {
    white-space: nowrap;
    animation: loop-anim 30s linear infinite;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: var(--color1);
}

.m-r-ticker {
    display: inline-block;
    margin-right: 200px;
}

@keyframes loop-anim {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: -50%
    }
}

@keyframes slide {
    0% {
        transform: translatex(100%)
    }

    100% {
        transform: translatex(-100%)
    }
}

.cstm-container2 {
    max-width: 1232px;
}

.std-padd {
    padding: 96px 0;
}

.icon-news {
    margin-bottom: 20px;
    height: 52px;
    width: auto;
}

.general-title {
    font-weight: 400;
    font-size: 48px;
    line-height: 120%;
    color: var(--color6);
    margin-bottom: 64px;
}

.img-news {
    height: 348.5px;
    width: 100%;
    object-fit: cover;
}

.img-news-container {
    background-color: var(--color7);
    height: 348.5px;
}

.news-box {
    padding: 20px;
    background-color: var(--color7);
    overflow: hidden;
}

.news-link.active {
    border: 1px solid var(--main-color);
}

.news-link:hover,
.news-link {
    text-decoration: none !important;
    display: inline-block;
    margin-bottom: 24px;
}

.news-date {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: var(--color6);
    display: inline-block;
    margin-bottom: 12.5px;
    text-transform: uppercase;
}

.news-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 140%;
    color: var(--color6);
    text-decoration: none;
    margin-bottom: 0;
}

.news-tooltip {
    background-color: var(--main-color);
    padding: 3.5px 5.5px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: var(--color1);
    text-decoration: none;
    display: inline-block;
    margin-right: 8px;
}

.news-tooltip:hover {
    color: var(--color1);
}

.icon-arrow-btn {
    height: 20px;
    width: auto;
    margin-left: 8px;
    vertical-align: sub;
}

.main-btn {
    background-color: var(--main-color);
    color: var(--color1);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 11.2px 15px;
    display: block;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    border: 1px solid var(--main-color);
}

.main-btn:hover {
    background-color: var(--main-color-dark);
    text-decoration: none;
    color: var(--color1);
}

.main-btn:focus {
    background-color: var(--main-color);
    color: var(--color1);
    border: 1px solid var(--main-color-dark);
}

.news-box.main .news-title {
    font-size: 28px;
    line-height: 130%;
    margin-bottom: 24px;
}

.news-box .news-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--color6);
    margin-bottom: 11.5px;
}

.news-box .news-text p:last-of-type {
    margin-bottom: 0;
}

.main-txt-btn {
    color: var(--main-color);
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 5px;
    display: inline-block;
    margin-top: 11.5px;
}

.main-txt-btn:hover {
    text-decoration: none;
    color: var(--main-color-dark);
}

.main-txt-btn:focus {
    text-decoration: none;
    color: var(--main-color-light);
}

.news-box.main {
    margin-bottom: 0;
}
.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; /* remove the gap so it doesn't close */
}
.nav-link:hover{
    cursor: pointer;
}
.last-rows-opacity {
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom,
            color-stop(0.00, rgba(0, 0, 0, 1)),
            color-stop(0.60, rgba(0, 0, 0, 1)),
            color-stop(1.00, rgba(0, 0, 0, 0)));
    mask-image: gradient(linear, left top, left bottom,
            color-stop(0.00, rgba(0, 0, 0, 1)),
            color-stop(0.60, rgba(0, 0, 0, 1)),
            color-stop(1.00, rgba(0, 0, 0, 0)));
}

.box-newsletter {
    padding: 24px;
    background-color: var(--color7);
    margin-top: 64px;
}

.title-newsletter {
    font-weight: 500;
    font-size: 28px;
    line-height: 130%;
    color: var(--color6);
    margin-bottom: 12px;
}

.info-newsletter {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--color6);
    margin-bottom: 12px;
}

.info-newsletter p:last-of-type {
    margin-bottom: 0;
}

.box-newsletter .small-txt-btn {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--color6);
    margin-top: 12px;
    display: inline-block;
    text-decoration: none;
}

.box-newsletter .small-txt-btn:hover {
    text-decoration: underline;
}

.email-newsletter {
    border-radius: 0;
    border: 1px solid var(--color8);
    padding: 12px 14px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    color: var(--color6);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    width: 427px;
}

.email-newsletter::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    color: var(--color6);
    opacity: 1;
    /* Firefox */
}

.email-newsletter:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    color: var(--color6);
}

.email-newsletter::-ms-input-placeholder {
    /* Microsoft Edge */
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    color: var(--color6);
}

.email-newsletter:focus,
.email-newsletter:active {
    outline: 0;
    border: 1px solid var(--color8);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
}

.submit-newsletter {
    background-color: var(--main-color);
    color: var(--color1);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 11.2px 20px;
    text-decoration: none;
    margin-left: 16px;
    border: 1px solid var(--main-color);
}

.submit-newsletter:hover {
    color: var(--color1);
    background-color: var(--main-color-dark);
}

.submit-newsletter:focus {
    color: var(--color1);
    background-color: var(--main-color);
    border: 1px solid var(--main-color-dark);
}

.kantorvstelling .main-btn {
    display: inline-block;
}

.kantorvstelling .main-btn img {
    height: 20px;
    width: auto;
    margin-right: 8px;
    vertical-align: sub;
}

.kantorvstelling .info-kantorvstelling {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--color6);
    margin-bottom: 48px;
}

.img-kantorvstelling-slider {
    width: 100%;
    height: 480px;
    object-fit: cover;
}


.kantorvstelling-slider .slick-next:before {
    /*font-family: "Font Awesome 5 Free";*/
    font-weight: 900;
    content: "\f138";
    font-size: 40px;
    color: var(--color1);
}

.kantorvstelling-slider .slick-prev:before {
    /*font-family: "Font Awesome 5 Free";*/
    font-weight: 900;
    content: "\f137";
    font-size: 40px;
    color: var(--color1);
}

.kantorvstelling-slider .slick-prev {
    left: 50px;
    z-index: 5;
}

.kantorvstelling-slider .slick-next {
    right: 50px;
    z-index: 5;
}

.kantorvstelling-slider .slick-next,
.kantorvstelling-slider .slick-prev {
    width: auto;
    height: auto;
}

.kantorvstelling-slider .slick-dots {
    bottom: 12px;
}

.kantorvstelling-slider .slick-dots li.slick-active button:before {
    color: var(--color1);
    opacity: 1;
}

.kantorvstelling-slider .slick-dots li button:before {
    color: var(--color9);
    opacity: 1;
    font-size: 14px;
    width: 12px;
    height: 12px;
}

.kantorvstelling-slider .slick-dots li button {
    height: auto;
    width: auto;
    padding: 0;
}

.kantorvstelling-slider .slick-dots li {
    margin: 0 3px;
}

.news .nav-tabs .nav-link {
    padding: 0;
    border: 2px solid transparent;
    margin-bottom: 24px;
    border-radius: 0;
    display: block;
}

.news .nav-tabs .nav-link.active,
.news .nav-tabs .nav-link:hover {
    border: 2px solid var(--main-color);
}

.news .nav-tabs {
    display: block;
}

footer .brand-part {
    background-color: var(--color6);
    padding: 96px 0 64px 0;
}

footer .contact-part {
    padding-bottom: 20px;
    background-color: var(--color6);
}

footer .title {
    font-weight: 600;
    font-size: 28px;
    line-height: 24px;
    color: var(--color1);
    margin-bottom: 4px;
}

footer .subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: var(--color7);
    letter-spacing: -0.02em;
    margin-bottom: 16px;

}

.footer-social-media {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 16px;
    font-size: 0;
}

.footer-social-media li {
    display: inline-block;
    margin: 0 13px;
}

.footer-social-media li a {
    font-size: 20px;
    color: var(--color1);
    line-height: 1;
}

.footer-social-media li a:hover {
    text-decoration: none;
    color: var(--main-color);
}

.contact-f-icon {
    max-height: 20px;
    max-width: 20px;
    vertical-align: top;
}

.wrap-f-icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    display: inline-block;
}

footer .small-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: var(--color1);
    margin-bottom: 16px;
    text-transform: uppercase;
}

.std-f-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 54px;
}

.std-f-list li .line {
    display: block;
}

.std-f-list li {
    margin-bottom: 8px;
    display: flex;
    width: fit-content;
}

.std-f-list li span,
.std-f-list li a {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--color1);
    text-decoration: none;
}

.wrap-f-icon.min {
    margin-right: 8px;
}

.contact-f-icon2 {
    font-size: 16px;
    color: var(--color1);
}

.std-f-list .main-hover:hover a,
.std-f-list .main-hover:hover i {
    color: var(--color20);
}

.reserv-f-btn:hover {
    color: var(--color4);
}

.second-hover:hover .reserv-f-icon {
    content: url("/assets/sven/img/reservation-icon-hover.png");
    color: var(--color4);
}

.second-hover .reserv-f-icon:hover .reserv-f-btn {
    color: var(--color4);
}

.modal-f-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: var(--color1);
    margin-bottom: 16px;
    text-transform: uppercase;
    background-color: transparent;
    text-decoration: none;
    border: 0;
}

.modal-f-btn:hover {
    color: var(--main-color);
}

.modal-f-btn:hover svg {
    fill: var(--main-color);
}


.wrap-d,
.opening-h {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--color1);
    display: block;
    margin-bottom: 4px;
}

.opening-h.active {
    color: var(--color10);
}

.mb-opening {
    margin-bottom: 54px;
}

.modal-f-btn.m2:hover img {
    content: url("/assets/sven/img/calendar-solid-hover.png");
}

.img-map {
    height: 180px;
    width: 100%;
    object-fit: cover;
    margin-bottom: 25px;
}

.modal-f-btn.m3:hover img {
    content: url("/assets/sven/img/cars-solid\ 1-hover.png");
}

footer .link-part {
    padding: 20px 0;
    background-color: var(--color11);
}

footer .logo-footer {
    height: 40px;
}

footer .std-f-msg {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--color7);
    display: inline-block;
    margin-bottom: 8px;
}

.link-part ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    margin-left: 24px;
}

.link-part ul li {
    margin-right: 24px;
    display: inline-block;
}

.link-part ul li a {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--color7);
    text-decoration: underline;
}

.link-part ul li:last-of-type {
    margin-right: 0;
}

footer .std-f-msg a.f-link {
    color: var(--main-color-light);
    text-decoration: none;
}

footer .std-f-msg a {
    color: var(--color1);
    text-decoration: none;
}

footer .std-f-msg.spc a {
    color: var(--color12);
    text-decoration: underline;
}

.modals .modal-dialog {
    max-width: 688px;
}

.modals .modal-header,
.modals .modal-content {
    border-radius: 0;
}

.modals .modal-header {
    padding: 24px 24px 21px 24px;
}

.icon-title-modal {
    height: 24px;
    width: auto;
    vertical-align: text-top;
    margin-right: 16px;
}

.modals .btn-close:focus {
    outline: 0;
    box-shadow: none;
}

.modals .modal-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 140%;
    color: var(--color6);
    margin-bottom: 0;
}

.modals .modal-body {
    padding: 12px 24px 24px 24px;
}

.modals .modal-subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: var(--main-color);
    margin-bottom: 15px;
}

.modals .std-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--color6);
    margin-bottom: 0;
}

.modals .std-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var();
}

.modals .modal-body ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.modals .modal-body hr {
    margin: 8px 0;
    color: var(--main-color);
    border: 0;
    border-top: 1px solid;
    opacity: .25;
}

.modals .modal-body ul li:last-of-type hr {
    display: none;
}

.modals .std-text-btn {
    color: var(--color20);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-decoration: underline;
    margin-bottom: 20px;
    display: inline-block;
}

#cookies {
    bottom: 50%;
}

.main-logo {
    margin-bottom: 45px;
    max-height: 60px;
}

.msk-start .main-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    color: var(--color2);
    margin-bottom: 0;
}

.msk-start .second-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    color: var(--color5);
    margin-bottom: 35px;
}

.msk-start .short-info {
    color: var(--color12);
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 64px;
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.contact-office {
    padding: 34px 24px;
    background-color: var(--main-color);
}

.wrap-office {
    padding-bottom: 24px;
    position: relative;
}

.wrap-office .name-office {
    background-color: var(--main-color-dark);
    margin-bottom: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: var(--color1);
    padding: 12px;
}

.msk-office-link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-decoration: none;
    /* No underlines on the link */
    z-index: 1;
    /* Places the link above everything else in the div */
    background-color: #FFF;
    /* Fix to make div clickable in IE */
    opacity: 0;
    /* Fix to make div clickable in IE */
    filter: alpha(opacity=1);
    /* Fix to make div clickable in IE */
}

.wrap-office .name-office span {
    text-transform: uppercase;
    font-weight: 600;
}

.contact-office ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.contact-office ul li {
    margin-bottom: 8px;
    display: flex;
    width: fit-content;
}

.contact-office ul li:last-of-type {
    margin-bottom: 0;
}

.contact-office li .line {
    display: block;
}

.contact-office li span,
.contact-office li a {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: var(--color1);
    text-decoration: none;
    z-index: 2;
}

.contact-office li a:hover {
    text-decoration: underline;
}

.all-links-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0;
    padding: 0;
    list-style: none;
}

.all-links-list li {
    margin: 20px;
}

.all-links {
    background-color: var(--color1);
    padding: 80px 0;
}

.cstm-container3 {
    max-width: 1248px;
}

.all-links a {
    font-weight: 500;
    font-size: 22px;
    line-height: 140%;
    text-transform: uppercase;
    color: var(--color12);
    text-decoration: none;
    border: 2px solid var(--color12);
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
    margin: 20px 8px;
}

.all-links a .name {
    padding: 14.5px 0;
}

.all-links a .icon-wrap {
    height: 100%;
    width: 60px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-right: 20px;
    background-color: var(--color12);
}

.icon-link {
    height: 32px;
    width: auto;
}

.all-links a:hover {
    border: 2px solid var(--main-color-dark);
    color: var(--main-color-dark);
}

.all-links a:hover .icon-wrap {
    background-color: var(--main-color-dark);
}

.downloads .accordion-button {
    padding: 32px 64px;
    background-color: var(--main-color-dark);
    border-radius: 0 !important;
    font-weight: 500;
    font-size: 48px;
    line-height: 120%;
    color: var(--color1);
    display: block;
    position: relative;
    transition: .5s ease all;
}

.accordion-button.collapsed {
    background-color: var(--main-color);
}

.downloads .name-d {
    display: block;
    margin-bottom: 8px;
    max-width: 768px;
    padding-right: 50px;
}

.downloads .accordion-button .short-info-d {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: var(--color1);
    max-width: 768px;
    padding-right: 50px;
}

.downloads .accordion-item {
    border-radius: 0;
    border: 0;
}

.downloads .accordion-button:focus,
.downloads .accordion-button:active {
    outline: 0;
    box-shadow: none;
}

.downloads .accordion-button::after {
    background-image: url("/assets/sven/img/chevrons-down-light.png") !important;
    background-size: contain;
    content: "";
    width: 35px;
    height: 40px;
    position: absolute;
    right: 64px;
    z-index: 3;
    top: 38%;
}

.downloads .accordion-body {
    padding: 20px 64px;
    border: 2px solid var(--main-color-dark);
}

.downloads .doc-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--color6);
    width: fit-content;
    display: block;
    text-decoration: none;
}

.downloads .doc-name:hover {
    color: var(--main-color-dark);
    text-decoration: none;
}

.downloads .doc-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--color12);
    width: fit-content;
    display: block;
}

.w-doc {
    margin-top: 6px;
    margin-bottom: 6px;
}

.downloads .general-title {
    color: var(--color1);
}

.bg-vac {
    background-color: var(--color7);
}

.wrap-vac {
    padding: 62.5px 32px;
}

.title-vac {
    font-weight: 500;
    font-size: 28px;
    line-height: 130%;
    color: var(--color6);
    margin-bottom: 40px;
}

.vacatures-slider {
    margin-bottom: 40px;
}

.vacatures-slider .slick-list {
    max-width: 90%;
    margin: 0 auto;
}

.img-vacatures {
    height: 400px;
    width: 100%;
    object-fit: cover;
}

.logo-vacatures {
    height: 82px;
    right: 32px;
    bottom: 20px;
    position: absolute;
    z-index: 2;
}

.icon-btn-vac {
    height: 20px;
    width: auto;
    vertical-align: sub;
    margin-left: 8px;
}

.vacatures .main-btn {
    display: inline-block;
}

.vacatures .name-vac {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: var(--main-color);
    margin-bottom: 0;
    text-align: left;
    width: fit-content;
}

.vacatures .name-vac-link {
    text-decoration: none;
}

.vacatures .info-vac {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #313131;
    display: block;
    text-align: left;
    width: fit-content;
}

.align-vac {
    text-align: left;
    width: fit-content;
    display: block;
    margin: 0 40px;
    max-width: 90%;
}

.align-vac-single {
    text-align: left !important;
    display: block;
    margin: 0 !important;
    max-width: 100% !important;
}

.vacatures .slick-next,
.vacatures .slick-prev {
    top: 50%;
    width: auto;
    height: auto;
}

.vacatures .slick-prev {
    left: 0;
    z-index: 5;
}

.vacatures .slick-next {
    right: -8px;
    z-index: 5;
}

.vacatures .slick-prev:before {
    content: url("/assets/sven/img/chevrons-left-regular.svg");
    width: 28px;
    height: 32px;
    display: block;
}

.vacatures .slick-next:before {
    content: url("/assets/sven/img/chevrons-right-regular.svg");
    width: 28px;
    height: 32px;
    display: block;
}

.img-public-service {
    height: auto;
    width: 200px;
}

.public-services .brd {
    display: block;
    border-right: 1px solid var(--color8);
    padding-top: 40px;
    padding-bottom: 40px;
    margin: 10px 0;
}

.public-services .brd:first-of-type {
    border-left: 0;
}

.public-services .brd:last-of-type {
    border-right: 0;
}


.link-public-service {
    display: flex;
    align-items: center;
    justify-content: center;
}

.public-services .brd.morethanfour:nth-child(3n) {
    border-right: 0;
}

.public-services .brd.morethanfour:nth-child(4n) {
    border-left: 0;
}

.icon-thema,
.icon-immo,
.icon-team,
.icon-tv {
    margin-bottom: 20px;
    height: 52px;
    width: auto;
}

.thema .general-title,
.immo .general-title,
.team .general-title,
.tv .general-title {
    margin-bottom: 20px;
}

.thema .general-subtitle,
.immo .general-subtitle,
.team .general-subtitle,
.tv .general-subtitle {
    margin-bottom: 64px;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: var(--color12);
    max-width: 760px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.thema-banner span {
    width: 100px;
    background-color: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.thema-banner .question-mark {
    height: 49px;
    width: auto;
    text-align: center;
}

.thema-banner .info {
    background-color: var(--color6);
    padding: 20px 15.5px;
    color: var(--color1);
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    display: flex;
    justify-content: space-between;
}

.thema-banner .info a {
    color: var(--main-color);
    text-decoration: none;
    display: inline-block;
}

.thema-banner .info p {
    margin-bottom: 0;
}

.thema-banner .icon-reservation {
    height: 28px;
    margin-right: 8px;
}

.thema-banner .wrap-btn .reserv-btn {
    background-color: var(--color4);
    color: var(--color1);
    text-transform: uppercase;
    padding: 16px 24px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: inline-block;
    text-align: center;
    margin-left: 10px;
    text-decoration: none;
}

.thema-banner .wrap-btn {
    background: var(--color6);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
}

.thema-banner {
    background-color: var(--color6);
    margin-top: 64px;
}

.link-thema {
    display: block;
    padding: 20px 20px 30px 20px;
    border: 1px solid var(--color8);
    background-color: var(--color7);
}

.thema-icon {
    height: 40px;
    margin-bottom: 32px;
}

.thema-slider .thema-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--color6);
}

.thema-slider .hr-cstm {
    width: 100px;
    height: 1px;
    display: block;
    border-bottom: 1px solid var(--color6);
    margin: 8px 0;
}

.thema-slider .link-thema {
    text-decoration: none !important;
    min-width: 260px;
}

.thema-slider .thema-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 0;
    color: var(--color6);
}

.thema-slider .link-thema:hover .thema-title,
.thema-slider .link-thema:hover .thema-subtitle {
    color: var(--color1);
}

.thema-slider .link-thema.thema1:hover {
    background-color: var(--color13);
    border: 1px solid var(--color13);
}

.thema-slider .link-thema.thema2:hover {
    background-color: var(--color14);
    border: 1px solid var(--color14);
}

.thema-slider .link-thema.thema3:hover {
    background-color: var(--color15);
    border: 1px solid var(--color15);
}

.thema-slider .link-thema.thema4:hover {
    background-color: var(--color16);
    border: 1px solid var(--color16);
}

.thema-slider .link-thema.thema5:hover {
    background-color: var(--color17);
    border: 1px solid var(--color17);
}

.thema-slider .link-thema.thema6:hover {
    background-color: var(--color18);
    border: 1px solid var(--color18);
}

.thema-slider .link-thema.thema7:hover {
    background-color: var(--color19);
    border: 1px solid var(--color19);
}

.thema-slider .link-thema.thema8:hover {
    background-color: var(--color20);
    border: 1px solid var(--color20);
}

.thema-slider .link-thema:hover .hr-cstm {
    border-bottom: 1px solid var(--color1);
}

.thema-slider .link-thema:hover .thema-icon.th1 {
    content: url("/assets/sven/img/house-building-thin-wh.png");
}

.thema-slider .link-thema:hover .thema-icon.th2 {
    content: url("/assets/sven/img/money-bill-transfer-thin\ 1-wh.png");
}

.thema-slider .link-thema:hover .thema-icon.th3 {
    content: url("/assets/sven/img/handshake-simple-thin-wh.png");
}

.thema-slider .link-thema:hover .thema-icon.th4 {
    content: url("/assets/sven/img/box-heart-thin-wh.png");
}

.thema-slider .link-thema:hover .thema-icon.th5 {
    content: url("/assets/sven/img/family-thin-wh.png");
}

.thema-slider .link-thema:hover .thema-icon.th6 {
    content: url("/assets/sven/img/people-arrows-thin-wh.png");
}

.thema-slider .link-thema:hover .thema-icon.th7 {
    content: url("/assets/sven/img/money-bill-trend-up-thin1-wh.png");
}

.thema-slider .link-thema:hover .thema-icon.th8 {
    content: url("/assets/sven/img/scale-balanced-thin-wh.png");
}

.thema-slider .slick-list {
    padding: 0 10% 0 0 !important;
}

.thema-slider .slick-slide {
    margin: 0 10px;
}

.thema-slider .slick-list {
    margin: 0 -10px;
}

.thema-slider {
    margin-bottom: 32px;
}

.controls-thema .slick-prev {
    position: relative;
    left: auto;
    top: auto;
    height: 32px;
    width: auto;
    display: inline-block;
    margin: 0 20px;
    transform: none;
}

.controls-thema .slick-next {
    position: relative;
    top: auto;
    right: auto;
    height: 32px;
    width: auto;
    display: inline-block;
    margin: 0 20px;
    transform: none;
}

.immo-img {
    height: 300px;
    width: 100%;
    object-fit: cover;
}
.top-box-immo::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    -webkit-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
    opacity: 1;
}
.box-immo:hover .top-box-immo::before{
    opacity: 0;
}
.top-box-immo {
    position: relative;
}

.biddit-logo {
    position: absolute;
    top: 12px;
    left: 12px;
    height: 45px;
}

.bar-price {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 4px 12px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: var(--color1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bar-price.clr1 {
    background-color: var(--color21);
}

.bar-price.clr2 {
    background-color: var(--color22);
}

.bar-price.clr3 {
    background-color: var(--color23);
}

.icon-bar-price {
    height: 18px;
    width: auto;
    margin-right: 8px;
}

.bar-price .price-immo {
    font-weight: 600;
}

.bottom-box-immo {
    background-color: var(--color7);
    padding: 12px;
}

.name-immo {
    margin-bottom: 8px;
    color: var(--color6);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-decoration: none;
    display: block;
}

.box-immo a {
    text-decoration: none !important;
}

.box-immo {
    margin-bottom: 32px;
}

.wrap-address {
    display: flex;
}

.address-immo {
    display: block;
}

.address-immo .line {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--color6);
}

.box-immo .icon-address {
    height: 20px;
    margin-right: 8px;
}

.immo .main-btn {
    display: inline-block;
    margin-top: 32px;
}

.immo .main-btn .icon-main-btn {
    height: 20px;
    transform: rotate(90deg);
    margin-left: 8px;
    vertical-align: sub;
}

.contact-f-icon.not-open {
    content: url("/assets/sven/img/opening-no.png");
}

.immo .main-btn.close .icon-main-btn {
    transform: rotate(-90deg);
}

.box-team-s1 {
    position: relative;
    margin-bottom: 24px;
}

.box-team-s1 .img-team-s1 {
    height: 547px;
    width: 100%;
    object-fit: cover;
}

.bottom-box-team-s1 {
    background: var(--main-color-dark);
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 12px 12px 0 12px;
    opacity: 0.9;
    min-height:120px;
}

.bottom-box-team-s1 .visible-part {
    transition: 1s ease all;
    min-height:120px;
}

.box-team-s1 .name-member {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
    color: var(--color1);
    margin-bottom: 12px;
}

.box-team-s1 .name-member span {
    text-transform: uppercase;
}

.box-team-s1 .info-member-l,
.box-team-s1 .info-member-f,
.box-team-s1 .info-member-s {
    color: var(--color1);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: block;
}

.box-team-s1 .info-member-s {
    text-transform: uppercase;
}

.box-team-s1 .no-img {
    height: 547px;
    width: 100%;
    background-color: var(--main-color);
    display: block;
}

.box-team-s1 .contact-member {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
    padding-top: 15px;
}

.box-team-s1 .contact-member li {
    margin: 2.5px 0;
}

.box-team-s1 .contact-member i {
    font-size: 16px;
    color: var(--color1);
    margin-right: 10px;
    margin-left: 4px;
    vertical-align: middle;
}

.box-team-s1 .contact-member a {
    color: var(--color1);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: inline-block;
    text-decoration: none;
}

.box-team-s1 .warning-icon {
    height: 23px;
    margin-right: 8px;
}

.box-team-s1 .warning-msg {
    color: var(--color1);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--color1);
    display: flex;
}

.hidden-part {
    max-height: 0;
    transition: max-height .6s ease-in;
}

.hidden-hover-vis {
    max-height: 400px;
    transition: max-height 1s linear;
}

.box-team-s1:hover .bottom-box-team-s1 .visible-part {
    height: auto;
}

.box-team-s1:hover .img-team-s1 {
    filter: grayscale(100%);
}

.team.team-style2 .general-subtitle {
    margin-bottom: 24px;
}

.team.team-style2 .main-btn {
    display: inline-block;
}

.team.team-style2 .main-btn.opened {
    margin-bottom: 64px;
}

.team .main-btn .icon-main-btn {
    transform: rotate(-90deg);
    height: 20px;
    vertical-align: sub;
    margin-left: 8px;
}

.team .main-btn {
    display: inline-block;
}

.box-team-s2 {
    padding: 12px;
    background-color: var(--color7);
    display: flex;
    min-height: 241.8px;
}

.img-team-s2 {
    height: 216px;
    width: 160px;
    object-fit: cover;
    margin-right: 16px;
    transition: .5s ease all;
}

.wrap-info-member .name-member {
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: var(--color6);
    text-transform: capitalize;
    margin-bottom: 5px;
}

.wrap-info-member .name-member span {
    text-transform: uppercase;
}

.box-team-s2 .info-member-l,
.box-team-s2 .info-member-f {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: block;
    color: var(--color12);
}

.box-team-s2 .info-member-s {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: var(--color12);
    text-transform: uppercase;
}

.box-team-s2 .contact-member i {
    color: var(--color6);
    font-size: 18px;
    vertical-align: middle;
    margin-right: 8px;
}

.box-team-s2 .contact-member {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 15px;
}

.box-team-s2 .contact-member a {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--main-color);
    display: block;
    width: fit-content;
    text-decoration: none;
    margin-bottom: 5px;
}

.box-team-s2 .contact-member a:hover {
    color: var(--main-color-dark);
}

.box-team-s2 .cstm-hr {
    margin: 8px 0;
}

.box-team-s2 .remark {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: var(--color12);
    text-transform: uppercase;
    display: block;
}

.box-team-s2 .msg-remark {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--color12);
    display: block;
}

.box-team-s2 {
    margin-bottom: 24px;
}

.mh-info-member-s2 {
    min-height: 69px;
}

.tv .main-btn {
    display: inline-block;
    margin-top: 64px;
}

.tv .main-btn .icon-main-btn {
    height: 20px;
    margin-left: 8px;
    vertical-align: sub;
}


.tv-slider .slick-list {
    padding: 0 10% 0 0 !important;
}

.tv-slider .slick-slide {
    margin: 0 10px;
}

.tv-slider .slick-list {
    margin: 0 -10px;
}

.tv-slider {
    margin-bottom: 32px;
}

.controls-tv .slick-prev {
    position: relative;
    left: auto;
    top: auto;
    height: 32px;
    width: auto;
    display: inline-block;
    margin: 0 20px;
    transform: none;
}

.controls-tv .slick-next {
    position: relative;
    top: auto;
    right: auto;
    height: 32px;
    width: auto;
    display: inline-block;
    margin: 0 20px;
    transform: none;
}

.tv-image-item {
    height: 280px;
    width: 100%;
    object-fit: cover;
}

.link-tv-item {
    display: block;
    position: relative;
}

.bar-tv {
    position: absolute;
    display: flex;
    bottom: 0;
    width: 100%;
}

.wrap-play {
    display: flex;
    align-items: center;
    background-color: var(--main-color);
    padding: 10px;
}

.icon-play {
    height: 40px;
}

.wrap-title {
    padding: 10px 32px;
    background-color: var(--color24);
}

.wrap-title span {
    color: var(--color1);
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    display: block;
}

.tv-slider .slick-list {
    padding: 0 20% 0 0 !important;
}

.link-tv-item::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    -webkit-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
    opacity: 1;
}

.link-tv-item:hover::before {
    opacity: 0;
}

.close-button {
    position: absolute;
    top: 50px;
    right: 10px;
    cursor: pointer;
}

.msk-header {
    display: inline-block;
    margin-top: 50px;
}

.cookie-button-left {
    margin-right: 15px;
}

.immo-item.empty {
    margin-bottom: 32px;
}

.immo-item.empty .box-immo {
    height: 100%;
    background-color: var(--color7);
}

.immo-animate {
    max-height: 0;
    overflow: hidden;
}

.immo-animate.show {
    max-height: 500px;
    transition: max-height 0.2s ease-in;
}

.hero {
    height: 100vh;
}

.overlay {
    position: absolute;
    background: rgba(18, 26, 40, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay_2 {
      inset: 0;
}

.pl-3 {
    padding-left: 30px;
}

.top-contact li svg {
    fill: var(--color1);
    margin-right: 8px;
	margin-top: -8px;
}

 .top-contact li:hover svg {
    fill: var(--main-color);
}

.top-contact li.address-location:hover svg {
    fill: var(--color1);
}

.top-social-media li a svg {
    fill: var(--color1);
    line-height: 1;
}

 .top-social-media li:hover svg {
    fill: var(--main-color);
}

.footer-social-media li svg {
    fill: var(--color1);
    margin-right: 8px;
	margin-top: -8px;
}

 .footer-social-media li:hover svg {
    fill: var(--main-color);
}

.std-f-list li svg {
    fill: var(--color1);
}
 .std-f-list li.email:hover svg {
    fill: var(--color20);
}

.box-team-s2 .contact-member li svg {
    fill: var(--color6);
	margin-right: 8px;
}

.box-team-s1 .contact-member li svg {
    fill: var(--color1);
	margin-right: 8px;
}

.wrap-f-icon svg {
    fill: var(--color1);
}

.kantorvstelling-slider .slick-next:before {
    content: url('data:image/svg+xml;utf8,<svg fill="white" height="35" viewBox="0 0 512 512" width="35" xmlns="http://www.w3.org/2000/svg"><path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z"/></svg>');
}

.kantorvstelling-slider .slick-prev:before {
    content: url('data:image/svg+xml;utf8,<svg fill="white" height="35" viewBox="0 0 512 512" width="35" xmlns="http://www.w3.org/2000/svg"><path d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zM142.1 273l135.5 135.5c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.4 9.4-24.6 0-33.9L226.9 256l101.6-101.6c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L142.1 239c-9.4 9.4-9.4 24.6 0 34z"></path></svg>');
}

.imp-info {
    color: var(--color12);
    font-size: 14px;
    margin-top: 30px;
}

.imp-info span{
    display: inline-block;
    margin-right: 15px;
}

.imp-info .line{
    display: block;
}
