/* Extra large devices */

@media screen and (max-width: 2559px) {}

/* Large devices (desktops, less than 1920) */

@media (max-width: 1920px) {}

/* Large devices (desktops, less than 1920) */

@media (max-width: 1600px) {}

/* Large devices (desktops, less than 1200px) */

@media (max-width: 1349.98px) {
    .main-list .nav-item .nav-link {
        padding: 22px 12px;
    }

    .all-links a {
        margin: 20px 0;
    }
}

@media (max-width: 1356px) {
    .main-list .nav-item .nav-link {
        padding: 22px 12px;
    }

    .all-links a {
        margin: 20px 0;
    }
}



/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .logo-title1 {
        font-size: 20px;
    }

    .logo-title2 {
        font-size: 16px;
    }

    .main-list .nav-item .nav-link {
        font-size: 14px;
    }

    .main-list .nav-item .nav-link.btn-reserv {
        font-size: 14px;
    }

    .hero-title {
        font-size: 36px;
    }

    .hero-small-content {
        font-size: 24px;
        margin-bottom: 16px;
    }

    .wrap-hero-info {
        max-width: 660px;
    }

    .kantorvstelling .info-kantorvstelling {
        margin-bottom: 30px;
    }

    .all-links a .icon-wrap {
        margin-right: 10px;
    }

    .all-links a {
        font-size: 16px;
    }

    .thema-banner .info {
        font-size: 18px;
    }

    .thema-banner .info .reserv-btn {
        padding: 16px 12px;
    }

    .thema-slider .slick-list {
        padding: 0 17% 0 0 !important;
    }
}

@media (max-width: 1099.98px) {
    .logo-title1 {
        font-size: 18px;
    }

    .logo-title2 {
        font-size: 14px;
    }

    .main-list .nav-item .nav-link.btn-reserv {
        font-size: 12px;
    }

    .main-list .nav-item .nav-link {
        font-size: 12px;
        padding: 23px 12px;
    }

    .all-links a {
        font-size: 14px;
    }
    .btn-reserv img {
        height: 18px;
        margin-right: 4px;
    }

    .top-contact li a,
    .top-contact li span {
        font-size: 14px;
    }

    .vcenter-start.adjust-mobile-p {
        display: block;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .link-part ul {
        margin-left: 12px;
    }

    .link-part ul li {
        margin-right: 12px;
    }

    .thema-slider .slick-list {
        padding: 0 15% 0 0 !important;
    }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {

    .top-contact li a,
    .top-contact li span {
        font-size: 12px;
        line-height: 18px;
    }

    .top-social-media li a {
        font-size: 16px;
    }

    .top-contact li i {
        font-size: 14px;
        margin-right: 5px;
    }

    .top-social-media li {
        margin: 0 8px;
    }

    .navbar-toggler {
        margin: 12px 0;
    }

    header .navbar {
        justify-content: flex-end;
    }

    .main-list .nav-item .nav-link.btn-reserv {
        font-size: 18px;
    }

    .main-list .nav-item .nav-link {
        font-size: 18px;
    }

    .navbar-nav.main-list {
        text-align: center;
    }

    .main-list .nav-item .nav-link {
        padding: 12px 5px;
    }

    .news-box.main .news-title {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .news-title {
        font-size: 20px;
    }

    .news-box.main {
        margin-bottom: 24px;
    }

    .general-title {
        font-size: 36px;
        margin-bottom: 45px;
    }

    .std-padd {
        padding: 56px 0;
    }

    .wrap-d,
    .opening-h {
        font-size: 12px;
    }

    footer .brand-part {
        padding: 66px 0 34px 0;
    }

    .std-f-list li span,
    .std-f-list li a {
        font-size: 12px;
    }

    .img-map {
        height: 160px;
    }

    .link-part ul {
        margin-left: 0;
        display: block;
    }

    .all-links a {
        margin: 10px 0;
    }

    .title-vac {
        font-size: 22px;
        margin-bottom: 35px;
    }

    .vacatures .slick-next,
    .vacatures .slick-prev {
        top: 40%;
    }

    .vacatures-slider {
        margin-bottom: 35px;
    }

    .wrap-vac {
        padding: 42.5px 32px;
    }

    .public-services .brd {
        border: 0;
    }

    .thema-slider .slick-list {
        padding: 0 5% 0 0 !important;
    }

    .tv-slider .slick-list {
        padding: 0 10% 0 0 !important;
    }

    .wrap-title {
        padding: 10px 15px;
        background-color: var(--color24);
    }
}

@media (max-width: 849.98px) {
    .thema-slider .slick-list {
        padding: 0 15% 0 0 !important;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .email-newsletter {
        width: 50%;
    }

    .kantorvstelling-slider .slick-next:before {
        font-size: 26px;
    }

    .kantorvstelling-slider .slick-prev:before {
        font-size: 26px;
    }

    .kantorvstelling-slider .slick-prev {
        left: 30px;
    }

    .kantorvstelling-slider .slick-next {
        right: 30px;
    }

    /*
    .top-bar {
        display: none;
    }
    */

    .top-contact {
        padding: 0;
        padding-top: 3px;
    }

    .top-contact .address {
        padding: 10px;
    }

    .top-lang-mobile {
        padding: 10px;
        margin: 0;
    }

    header .navbar {
        margin-top: -64px;
    }

    .btn-hero {
        display: block;
        text-align: center;
        font-size: 18px;
    }

    .wrap-hero-info {
        max-width: none;
        padding-right: 15px;
    }

    .kantorvstelling .main-btn {
        display: block;
        text-align: center;
    }

    .brand-part .cstm-container2,
    .contact-part .cstm-container2 {
        padding: 0 25px;
    }

    .img-map {
        height: 300px;
    }

    .wrap-d,
    .opening-h {
        font-size: 14px;
    }

    .std-f-list li span,
    .std-f-list li a {
        font-size: 14px;
    }

    .std-f-list {
        margin-bottom: 20px;
    }

    .mb-opening {
        margin-bottom: 20px;
    }

    .link-part ul li {
        margin-right: 20px;
    }

    #cookies .main-btn {
        font-size: 15px;
    }

    .msk-start .second-title {
        font-size: 20px;
        line-height: 1.5;
    }

    .downloads .accordion-button {
        padding: 32px 40px;
    }

    .downloads .accordion-body {
        padding: 32px 40px;
    }

    .downloads .accordion-button::after {
        right: auto;
        top: auto;
        margin: 30px auto 10px auto;
        position: relative;
        display: block;
    }

    .downloads .accordion-button .short-info-d,
    .downloads .name-d {
        padding-right: 0;
        width: 100%;
    }

    .vacatures .slick-next,
    .vacatures .slick-prev {
        top: 35%;
    }

    .vacatures-slider {
        margin-bottom: 40px;
    }

    .wrap-vac {
        padding: 22.5px 32px;
    }

    .img-vacatures {
        height: 350px;
    }

    .logo-vacatures {
        height: 52px;
    }

    .public-services .brd {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .thema-banner .info {
        display: block;
        width: 100%;
    }

    .thema-banner .wrap-btn {
        display: block;
        margin: 20px 0;
        background: none;
        width: 100%;
        padding-right: 0;
    }

    .thema-banner .wrap-btn .reserv-btn {
        display: block;
        margin-left: 0;
    }

    .thema-banner span {
        padding: 20px 10px;
        width: 100%;
    }

    .box-team-s1 .img-team-s1 {
        height: 427px;
    }

    .tv-slider .slick-list {
        padding: 0 0 0 0 !important;
    }

    .thema .general-subtitle,
    .immo .general-subtitle,
    .team .general-subtitle,
    .tv .general-subtitle {
        margin-bottom: 44px;
    }

    .tv .main-btn {
        margin-top: 34px;
    }

    .wrap-play {
        padding: 5px;
    }

    .wrap-title {
        padding: 5px 5px;
    }
    .bar-price {
        padding: 4px 5px;
        font-size: 14px;
    }
}

@media (max-width: 649.98px) {

    .wrap-d,
    .opening-h {
        font-size: 12px;
    }

    .std-f-list li span,
    .std-f-list li a {
        font-size: 12px;
    }

    #cookies .main-btn {
        font-size: 14px;
        line-height: 20px;
        padding: 10px 8px;
    }

    .thema-slider .slick-list {
        padding: 0 6% 0 0 !important;
    }
}

@media (max-width: 575.98px) {
    .logo-title1 {
        font-size: 16px;
        padding-right: 45px;
    }

    .logo-title2 {
        font-size: 12px;
        padding-right: 45px;
    }


    .main-list .nav-item .nav-link {
        font-size: 16px;
    }

    .main-list .nav-item .nav-link.btn-reserv {
        font-size: 16px;
    }

    .hero-title {
        font-size: 32px;
    }

    .hero-small-content {
        font-size: 20px;
    }

    .email-newsletter {
        width: 80%;
        margin-bottom: 12px;
    }

    .title-newsletter {
        font-size: 24px;
    }

    .general-title {
        font-size: 26px;
        margin-bottom: 25px;
    }

    .std-padd {
        padding: 46px 0;
    }

    .vcenter-start.adjust-mobile-p {
        text-align: center;
    }

    .img-kantorvstelling-slider {
        height: 400px;
    }

    .wrap-d,
    .opening-h {
        font-size: 16px;
    }

    .std-f-list li span,
    .std-f-list li a {
        font-size: 16px;
    }

    .brand-part .cstm-container2,
    .contact-part .cstm-container2 {
        padding: 0 35px;
    }

    footer .brand-part {
        padding: 35px 0 15px 0;
    }

    footer .subtitle {
        line-height: 1.5;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    footer .small-title,
    .modal-f-btn {
        font-size: 18px;
    }

    .modal-f-btn {
        margin-bottom: 30px;
        display: inline-block;
    }

    .contact-f-icon2 {
        font-size: 18px;
        color: var(--color1);
    }

    .std-f-list li {
        margin-bottom: 12px;
    }

    .std-f-list {
        margin-bottom: 30px;
    }

    .modals .modal-title {
        font-size: 20px;
    }

    .modals .modal-subtitle {
        font-size: 16px;
    }

    .modals .modal-header {
        padding: 15px 15px 12px 15px;
    }

    .modals .modal-body {
        padding: 12px 15px 15px 15px;
    }

    .modals .std-title {
        font-size: 14px;
    }

    #cookies .main-btn {
        display: block;
        margin-bottom: 15px;
    }

    .msk-start .second-title {
        font-size: 16px;
    }

    .msk-start .main-title {
        font-size: 24px;
    }

    .msk-start .short-info {
        font-size: 14px;
    }

    .title-vac {
        margin-bottom: 20px;
        font-size: 20px;
    }

    .vacatures .slick-next,
    .vacatures .slick-prev {
        top: 25%;
    }

    .vacatures-slider {
        margin-bottom: 20px;
    }

    .vacatures .name-vac {
        font-size: 16px;
    }

    .vacatures .info-vac {
        font-size: 14px;
    }

    .wrap-vac {
        padding: 20px 30px 20px 20px;
    }

    .align-vac {
        max-width: 70%;
    }

    .public-services .brd {
        border: 0;
    }

    .thema-banner span {
        width: 100%;
        display: block;
    }

    .thema-banner .info {
        width: 100%;
    }

    .thema-banner .question-mark {
        display: block;
        margin: 0 auto;
    }

    .mh-t-bnr {
        padding: 20px 0;
    }

    .thema-slider .slick-list {
        padding: 0 35% 0 0 !important;
    }

    .box-team-s2 {
        display: block;
        text-align: center;
    }

    .img-team-s2 {
        height: 250px;
        margin: 0 auto;
        display: block;
        margin-bottom: 20px;
    }

    .box-team-s2 .contact-member i {
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
    }

    .box-team-s2 .contact-member a {
        display: block;
        width: 100%;
        margin-bottom: 10px;
    }

    .mh-info-member-s2 {
        margin-bottom: 20px;
    }

    .tv-slider .slick-list {
        padding: 0 15% 0 0 !important;
    }

    .wrap-play {
        padding: 10px;
    }

    .wrap-title {
        padding: 10px;
    }
    .bar-price {
        padding: 4px 12px;
        font-size: 16px;
    }

    .cookie-button-left {
        margin-right: 0;
    }

    .top-contact {
        padding: 0;
    }
}

@media (max-width: 445.98px) {
    .email-newsletter {
        width: 100%;
    }

    .submit-newsletter {
        width: 100%;
        margin-left: 0;
    }

    .brand-part .cstm-container2,
    .contact-part .cstm-container2 {
        padding: 0 15px;
    }

    .thema-slider .slick-list {
        padding: 0 25% 0 0 !important;
    }

    .tv-slider .slick-list {
        padding: 0 5% 0 0 !important;
    }
}
